/* Font import */
@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

/* Variables 
 main color - rgb(45, 20, 244) or #2d14f4
 nav bg light- rgba(255, 246, 246, 0.1) or FFF6F6 
 nav bg dark - rgba(21, 21, 21, 1)
*/
:root {
  --logo-main-color: #2d14f4;
  --nav-bg-color: rgba(21, 21, 21, 1);
  --nav-menu-item-color: #fff;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* Alternative */

.survey-img {
  display: flex;
  justify-content: center;
  width: 600px;
  height: 200px;
}

.survey-btn {
  display: flex;
  justify-content: center;
  justify-content: space-between;
  align-items: center;
  color: var(--nav-menu-item-color);
  line-height: 60px;
}

/* search results view */
.exactMatch {
  width: 50vw;
}

@media only screen and (min-width: 769px) {
  .main-text {
    width: 80%;
    margin: 0 auto 2rem auto;
  }
  .sub-text {
    width: 60%;
    margin: 0 auto 2rem auto;
  }
}

@media only screen and (max-width: 720px) {
  .exactMatch {
    width: 80vw;
  }
}

.summary h2 {
  margin: 3rem auto;
  text-align: center;
}

/* new nav using bootsrap-react */

.navbar {
  max-height: 6em;
}

.navbar-light .navbar-nav .nav-link {
  color: #2d14f4;

  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.5;

  padding-right: 1em;
}

.navbar-light #responsive-navbar-nav .navbar-nav .nav-link:hover,
.navbar-light #responsive-navbar-nav .navbar-nav .nav-link:focus {
  color: rgba(45, 20, 244, 0.7);
}

.logo-image {
  max-width: 30%;
  max-height: 30%;
}

.navbar-brand {
  flex: 1;
  padding-left: 2em;
}

#responsive-navbar-nav {
  flex-direction: row-reverse;
  flex: 3;
}

.logo {
  color: var(--logo-main-color);
  position: relative;
  top: 4px;
  font-family: "Anton", sans-serif;
  font-style: italic;
  letter-spacing: 1px;
  display: inline-block;
  font-size: 24px;
  font-weight: bold;
  color: var(--logo-main-color);
  text-decoration: none;
}
.logo:hover {
  /* color: #4169e1; */
  opacity: 0.75;
}

/* Responsiveness
*/

@media (max-width: 768px) {
  .navbar {
    max-height: 100%;
  }

  .logo-image {
    width: 10%;
  }

  #responsive-navbar-nav {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center;
  }

  .navbar-nav .nav-link {
    align-self: center;
  }

  .navbar-brand {
    flex: 1;
    justify-content: flex-end;
    text-align: center;
    padding: 0 0;
    margin-right: 2px;
  }

  .navbar-light .navbar-nav .nav-link {
    padding-right: 0;
  }
  #about {
    border-left: 1px solid transparent !important;
    order: 1;
  }
  #about h4 {
    font-size: 1rem;
  }
  #img {
    order: 2;
  }
}

.summary h2 {
  margin: 3rem auto;
  text-align: center;
}


